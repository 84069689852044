.header {
	/* padding-top: 8rem; */
	/* padding-bottom: 9rem; */
	background-color: #F4F8FF;
	text-align: center;
}

.container,.container-fluid,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl{width:100%;padding-right:var(--bs-gutter-x,.75rem);padding-left:var(--bs-gutter-x,.75rem);margin-right:auto;margin-left:auto}@media (min-width:576px)
{.container,.container-sm{max-width:540px}}@media (min-width:768px){.container,.container-md,.container-sm{max-width:720px}}@media (min-width:992px){.container,.container-lg,.container-md,.container-sm{max-width:960px}}@media (min-width:1200px){.container,.container-lg,.container-md,.container-sm,.container-xl{max-width:1140px}}@media (min-width:1400px){.container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl{max-width:1320px}}



.header .text-container {
	margin-bottom: 5rem;
}

.header .h1-large {
    margin-top: 10rem;
	margin-bottom: 1.25rem;
}


.header .replace-me {
	color: #081078;
}



.header .replace-me span {
	text-decoration: underline;
}

.header .p-large {
	margin-bottom: 2.75rem;
}

.header .btn-solid-lg {
	display: block;
	margin-bottom: 1.75rem;
}

.header .text-container {
	margin-bottom: 5rem;
}

.p-large {
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.modal-content .image-container {
	margin-bottom: 3rem;
}

.img-1 {
	margin-top: 5rem;
}

.btn-solid-lg {
	display: inline-block;
	padding: 1.625rem 2.625rem 1.625rem 2.625rem;
	border: 1px solid #272556;
	border-radius: 30px;
	background-color: #272556;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
	width: 100%;
}

.btn-solid-lg:hover {
	background-color: transparent;
	color: #272556; /* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

.header .btn-solid-lg {
	display: block;
	margin-bottom: 1.75rem;
}

.header .text-container {
	margin-bottom: 5rem;
}

.header .btn-solid-lg {
	display: inline-block;
	margin-bottom: 0;
}

.btn12{    
    padding:0px 20px;
    margin: 0 10px;
    display: inline-block;
    text-decoration:none;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase!important;
    letter-spacing: 1px;
    color: #272556;
    cursor: hand;
    text-align: center;
    text-transform: capitalize;
    border-radius:0px;
    position: relative;
	border-radius: 10px;
    overflow: hidden!important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background: transparent!important;
	
}